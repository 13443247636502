let shadowRoot;

function isDST(date) {
    const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.min(january, july) !== date.getTimezoneOffset();
}

function getEasternTime(now) {
    // Calculate the current time in Eastern Time (ET)
    const options = { timeZone: 'America/New_York', hour12: false, hour: 'numeric', minute: 'numeric' };
    const formatter = new Intl.DateTimeFormat([], options);
    const parts = formatter.formatToParts(now);
    const hour = parseInt(parts.find(part => part.type === 'hour').value, 10);
    return hour;
}

function checkBusinessHours() {
    const now = new Date();
    const day = now.getDay(); // Sunday - Saturday: 0 - 6
    const hourET = getEasternTime(now); // Current hour in Eastern Time (ET)
    
    const startHourET = 8; // 8 AM ET
    const endHourET = 19; // 7 PM ET

    // Hide all divs initially
    const openHoursInfo = document.querySelector('.open-hours-info');
    const satHoursInfo = document.querySelector('.sat-hours-info');
    const closedInfo = document.querySelector('.closed-info');

    if (openHoursInfo) openHoursInfo.style.display = 'none';
    if (satHoursInfo) satHoursInfo.style.display = 'none';
    if (closedInfo) closedInfo.style.display = 'none';

    // Show the appropriate div based on the current day and time
    if (day >= 1 && day <= 5 && hourET >= startHourET && hourET < endHourET) {
        if (openHoursInfo) openHoursInfo.style.display = 'block';
    } else if (day === 6 && hourET >= startHourET && hourET < endHourET) {
        if (satHoursInfo) satHoursInfo.style.display = 'block';
    } else {
        if (closedInfo) closedInfo.style.display = 'block';
    }
}


window.addEventListener('heyflow-init', (event) => {
    
    // Call the function when the page loads
    checkBusinessHours();

    // Optionally, you can check every minute to ensure it updates correctly
    setInterval(checkBusinessHours, 60000);

    // Determine if there is a shadow DOM
    shadowRoot = (document.querySelector(`[flow-id="${event.detail.flowID}"]`) && 
                  document.querySelector(`[flow-id="${event.detail.flowID}"]`).shadowRoot) || 
                  document;

    console.log('shadowRoot:', shadowRoot);

    // Function to remove commas from textarea values
    function removeCommasFromTextareaValues() {
        // List of data-variable values to target
        const dataVariables = [
            'additional_note-future',
            'additional_note-current',
            'additional_note-estimate',
            'additional_note-claim',
            'reason_description-future',
            'reason_description-current',
            'reason_description-estimate',
            'reason_description-claim'
        ];

        // Iterate over each data-variable value
        dataVariables.forEach(variable => {
            // Locate all elements with the current data-variable
            let textareas = shadowRoot.querySelectorAll(`[data-variable="${variable}"]`);
            console.log(`textareas with data-variable="${variable}":`, textareas);

            // Iterate over each textarea found
            textareas.forEach((textarea, index) => {
                // Log the current value of the textarea
                console.log(`Current textarea value ${index + 1} for ${variable}:`, textarea.value);

                if (textarea.value) {
                    // Remove all commas from the value
                    let modifiedValue = textarea.value.replace(/,/g, '');
                    // Update the value of the textarea
                    textarea.value = modifiedValue;
                    console.log(`Modified value ${index + 1} for ${variable}:`, modifiedValue); // Log the modified value
                } else {
                    console.warn(`Textarea value ${index + 1} for ${variable} is empty`);
                }
            });
        });
    }

    // Update the values on page load
    removeCommasFromTextareaValues();

    // Optional: Update the values when the form is submitted
    window.addEventListener('heyflow-submit', () => {
        removeCommasFromTextareaValues();
    });
});